/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  .contentStyleMain{
 
    color: rgb(255, 255, 255);
    background-color: rgb(43, 113, 255);
    margin-left: 1%;
    margin-right: 1%;
    display: flex;
    flex-direction: column;
    padding-left: 20%;
    padding-top: 10%;
    padding-right: 20%;
    border-radius: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.5px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item {
    font-size: 32px;
    color: #fff;
  }

  .textHead{
    color:#fff;
    font-size: 32px;
  
  }

.Input {
  background-color: transparent;
  display: flex;
  overflow: hidden;
  border: none;
  margin: 0;
  width:65px;
  font-size: 24px;
  border: none;
  color:#fff;

}


  .textWallet{
    display: none;
   }

  .maticCol{
     padding: 20%;
  }

  .finCol{
    padding: 20%;
  }

  .midCol{
    align-items: center;
    text-align: center;
  }

  .swapIcon{
    text-align: center;
    margin-left: 100px;
    font-size:42px;
    margin-top:50px;
    margin-bottom:50px;
    display: none;
   
  }

  .navBarLogo{
    text-align: start;
    margin-left: -12%;
    margin-top: 5%;

    }

    .navBarButton{
      text-align: end;
     margin-top: 2%;
     margin-right: -12%;
  
      }
      .modalMint{
        margin-left:3%;
        margin-top: 5%;
        margin-bottom: 5%;
      }
      
  
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  
  .contentStyleMain{

    color: rgb(255, 255, 255);
    background-color: rgb(43, 113, 255);
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.5px;
  }
.Input {
  background-color: transparent;
  display: flex;
  overflow: hidden;
  border: none;
  margin: 0;
  width:85px;
  font-size: 42px;
  border: none;
  color:#fff;

}
.textHead{
  color:#fff;
  font-size: 42px;

}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  font-size: 42px;
  color: #fff;
}
  
  .maticCol{
    text-align:end;
  }

  .finCol{
    text-align:start;
  }

  .swapIcon{
    font-size:42px;
    margin-top:50px;
   
  }

  .navBarLogo{
    text-align: start;
  
    margin-top: 5%;
 }

    .navBarButton{
      text-align: end;
     margin-top: 4%;
 }
 .textWallet{
  display: block;
 }
 .modalMint{
  margin-left:18%;
}


}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {

  .contentStyleMain{
  
    color: rgb(255, 255, 255);
    background-color: rgb(43, 113, 255);
    margin-left: 7%;
    margin-right: 7%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.5px;
    
  }

  .midCol{
    width: 200px;
  }

  .swapIcon{
    font-size:42px;
    margin-top:25%;
    margin-bottom:25%;
  }

  .navBarLogo{
    text-align: start;
    margin-left: 2%;
    margin-top: 2%;

    }

    .navBarButton{
      text-align: end;
     margin-top: 1%;
     margin-right: 2%;

  
      }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .contentStyleMain{
  
    color: rgb(255, 255, 255);
    background-color: rgb(43, 113, 255);
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    box-sizing: border-box;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.5px;


  }

  .navBarLogo{
    text-align: start;
    margin-left: 7%;
    margin-top: 2%;

    }

    .navBarButton{
      text-align: end;
     margin-top: 1%;
     margin-right: 7%;

  
      }

}






.contentStyleMainChild{
 
  color: rgb(255, 255, 255);
  background-color: rgb(43, 113, 255);
  margin-top: 5%;
  margin-bottom: 5%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  letter-spacing: 0.5px;

}



.textPara{
  font-weight: 300;
  text-decoration: none;
  opacity: 1;
  width: 100%;

  color: rgba(255, 255, 255, 0.75);
  cursor: inherit;
  font-size: 20px;
  line-height: 24px;
}

.textParaHead{
  font-weight: 500;
  text-decoration: none;
  opacity: 1;
  width: 100%;
  text-align: center;
  color: #fff;
  cursor: inherit;
  font-size: 24px;

}






.ant-select .ant-select-arrow {
  color: #fff;
}


.InputA {
  background-color: rgba(236, 239, 240, 0.129);
  display: flex;
  overflow: hidden;
  border: none;
  margin: 10;
  padding: 4%;
  border-radius: 4px;
  width:85px;
  font-size: 16px;
  border: none;
  color: rgba(255, 255, 255, 0.75);

}

.InputB {
  background-color: rgba(236, 239, 240, 0.129);
  display: flex;
  overflow: hidden;
  border: none;
  margin: 10;
  padding: 4%;
  border-radius: 4px;
  width:130px;
  font-size: 16px;
  border: none;
  color: rgba(255, 255, 255, 0.144);

}

input:focus{
  outline: none;
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #fff;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: #fff;
}

::-ms-input-placeholder { /* Microsoft Edge */
  color: #fff;
}


